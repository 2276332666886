import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Task } from "@prisma/client"

import { RootState } from "../store"

interface TasksState {
  tasks: Task[]
}

const initialState: TasksState = {
  tasks: [],
}

const tasksSlice = createSlice({
  name: "tasks",
  initialState,
  reducers: {
    setTasks: (state, action: PayloadAction<Task[]>) => {
      state.tasks = action.payload
    },
    addStoreTask: (state, action: PayloadAction<Task>) => {
      state.tasks.push(action.payload)
    },
    deleteStoreTask: (state, action: PayloadAction<number>) => {
      const taskIndex = action.payload

      if (taskIndex >= 0 && taskIndex < state.tasks.length) {
        state.tasks.splice(taskIndex, 1)
      }
    },
    updateStoreTask: (state, action: PayloadAction<{ index: number; task: Task }>) => {
      const { index, task } = action.payload

      if (index >= 0 && index < state.tasks.length) {
        state.tasks[index] = task
      }
    },
  },
})

export const { setTasks, addStoreTask, deleteStoreTask, updateStoreTask } = tasksSlice.actions

export const selectTasks = (state: RootState) => state.tasks.tasks

export default tasksSlice.reducer
