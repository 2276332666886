import { Suspense } from "react";
import { Provider } from "react-redux";
import { ChakraProvider } from "@chakra-ui/react";
import "src/styles/global.css";
import { SessionProvider } from "next-auth/react";
import { HighlightInit } from "@highlight-run/next/client";
import { store } from "src/store/store";
import theme from "src/styles/theme";
import FallbackLoadingAnimation from "src/components/Loading/FallbackLoadingAnimation";
import { ToastProvider } from "src/hooks/useToastContext";
import "sentry.client.config";
const App = ({
  Component,
  pageProps: {
    session,
    ...pageProps
  }
}) => {
  const getLayout = Component.getLayout || ((page: JSX.Element) => page);
  return <>
      <HighlightInit projectId="mem4kpye" environment={process.env.NEXT_PUBLIC_APP_ENV} serviceName="feynman-frontend" tracingOrigins={["localhost:3000/api", "https://staging.useintegral.com/api", "https://useintegral.com/api"]} networkRecording={{
      enabled: true,
      recordHeadersAndBody: true,
      urlBlocklist: []
    }} />

      <Suspense fallback={<FallbackLoadingAnimation />}>
        <Provider store={store}>
          <ChakraProvider theme={theme}>
            <ToastProvider>
              <SessionProvider session={session}>{getLayout(<Component {...pageProps} />)}</SessionProvider>
            </ToastProvider>
          </ChakraProvider>
        </Provider>
      </Suspense>
    </>;
};
export default App;