import React, { createContext, useContext } from "react"

import { HStack, useToast, Text } from "@chakra-ui/react"
import { FiAlertTriangle, FiCheck } from "react-icons/fi"

const ToastContext = createContext({
  showSuccess: (message) => {},
  showError: (message) => {},
})

export const ToastProvider = ({ children }) => {
  const toast = useToast()

  const showSuccess = (message: string) => {
    toast({
      title: message,
      status: "success",
      duration: 3000,
      render: () => (
        <HStack
          backgroundColor="var(--green-2)"
          color="white"
          boxShadow="0 0 15px lightgray"
          borderRadius="8px"
          fontWeight="500"
          p="12px 20px"
          alignItems="center"
          userSelect="none"
          pointerEvents="none"
          justifyContent="center"
          width="fit-content"
          margin="auto"
          mb="12px"
        >
          <FiCheck /> <Text color="white">{message}</Text>
        </HStack>
      ),
    })
  }

  const showError = (message: string) => {
    toast({
      title: message,
      status: "error",
      duration: 5000,
      render: () => (
        <HStack
          backgroundColor="var(--red-2)"
          color="white"
          boxShadow="0 0 15px lightgray"
          borderRadius="8px"
          fontWeight="500"
          p="12px 20px"
          alignItems="center"
          userSelect="none"
          pointerEvents="none"
          margin="auto"
          width="fit-content"
          mb="12px"
          justifyContent="center"
        >
          <FiAlertTriangle /> <Text color="white">{message}</Text>
        </HStack>
      ),
    })
  }

  return <ToastContext.Provider value={{ showSuccess, showError }}>{children}</ToastContext.Provider>
}

export const useToastContext = () => useContext(ToastContext)
