import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { CertificationReminder } from "@prisma/client"

import { RootState } from "../store"

export interface UserState {
  user: Record<string, any> | undefined
  certificationReminders: CertificationReminder[]
  status: "idle" | "loading" | "succeeded" | "failed"
  error: string | null | undefined
}

const initialState: UserState = {
  user: undefined,
  certificationReminders: [],
  status: "idle",
  error: null,
}

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<UserState["user"]>) => {
      state.user = { ...action.payload }
      state.certificationReminders = action.payload?.certificationReminders || []
    },
    clearUser: () => {
      localStorage.clear()
      return initialState
    },
    updateUserName: (state, action: PayloadAction<{ name: string }>) => {
      if (state.user) {
        state.user.name = action.payload.name
      }
    },
    updateUserImage: (state, action: PayloadAction<{ imageSource: string }>) => {
      if (state.user) {
        state.user.imageSource = action.payload.imageSource
      }
    },
  },
})

export const { setUser, clearUser, updateUserName, updateUserImage } = userSlice.actions

export const selectUser = (state: RootState) => state.user.user

export default userSlice.reducer
