import { extendTheme } from "@chakra-ui/react"

import { tabsTheme } from "./tabs"

const theme = extendTheme({
  components: {
    Tabs: tabsTheme,
  },
})

export default theme
