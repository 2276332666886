import { Box } from "@chakra-ui/react"
import Image from "next/image"

const FallbackLoadingAnimation = () => {
  return (
    <Box h="100vh">
      <Box position="absolute" top="calc(50% - (200px / 2))" left="calc(50% - (200px / 2))">
        <Image width={200} height={200} style={{ margin: "0 auto" }} src="/images/loading.webp" alt="spinning animation" />
      </Box>
    </Box>
  )
}

export default FallbackLoadingAnimation
