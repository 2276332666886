import { tabsAnatomy } from "@chakra-ui/anatomy"
import { createMultiStyleConfigHelpers } from "@chakra-ui/react"

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(tabsAnatomy.keys)

// define the base component styles
const baseStyle = definePartsStyle({
  tab: { fontWeight: "bold" },
})

// define custom sizes
const sizes = {
  md: definePartsStyle({
    tab: {
      fontSize: "sm",
      padding: "4px 16px",
    },
  }),
}

// define custom variants
const squareVariant = definePartsStyle((props) => {
  return {
    tab: {
      borderRadius: "8px",
      bg: "#f5f5f5",
      color: "#a3a3a3",
      _selected: {
        border: "1px solid var(--secondary)",
        bg: "#f7f1ff",
        color: "var(--secondary)",
        margin: "0px -8px",
        zIndex: 999,
      },
    },
  }
})

const variants = {
  square: squareVariant,
}

// export the component theme
export const tabsTheme = defineMultiStyleConfig({
  baseStyle,
  sizes,
  variants,
  defaultProps: { variant: "square" },
})
